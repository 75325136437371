<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div
  class="parallax-container"
  [style.viewTransitionName]="(isHandset$ | async) ? '' : viewTransitionName()"
>
  <div
    #parallax
    class="parallax"
  >
    <div
      class="parallax-header-img"
      [style.viewTransitionName]="(isHandset$ | async) ? viewTransitionName() : ''"
    >
      <div
        #rellax
        appRellax
      >
        @if(backgroundImage) {
        <img
          sizes=""
          [alt]="title"
          [appSrcset]="backgroundImage"
          (load)="imageLoad()"
        >
        } @else {
        <div class="placeholder">
          <span>{{title}}</span>
        </div>
        }
      </div>
    </div>
    <div class="parallax-header-title-container">
      @if (image) {
      <img
        [alt]="title"
        [src]="image"
        [style.viewTransitionName]="'avatar-' + title"
      >
      }
      <div class="parallax-header-title">
        <a
          class="anchor"
          name="tab"
          id="tab"
        >Anchor</a>
        <h1
          class="mat-headline-4 title"
          [style.viewTransitionName]="'title-' + title"
        >{{title}}</h1>
        <div class="subtitle">
          @if (subtitle) {
          <p class="mat-subtitle-2">{{subtitle}}
            <ng-content select="subtitle" />
          </p>
          }
        </div>
      </div>
      <ng-content
        class="parallax-header-right"
        select="[parallax-header-right]"
      />
    </div>
  </div>

  @if (tabs.length) {
  <nav
    mat-tab-nav-bar
    [tabPanel]="tabPanel"
  >
    @for (tab of tabs; track track($index, tab)) {

    @if (!tab.hidden) {
    <a
      #rla="routerLinkActive"
      mat-tab-link
      routerLinkActive
      fragment="tab"
      [active]="rla.isActive"
      [routerLink]="tab.link"
    >
      {{tab.label}}
    </a>
    }

    }
  </nav>
  }
</div>
